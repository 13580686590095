<template>
  <div>
    <app-page-detail page-title="Edit BA Kronologis ABD" page-toolbar-title="Edit BA Kronologis ABD" :record-not-found="notFound">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <!-- <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button> -->
        <app-log-data-dialog module_name="baabd" :id="baabd_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Finance.Baabd.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button @click="redirect('Finance.Baabd.PrintPreview', { id: baabd_header.id })" title="Print Preview" mdi-icon="mdi-printer"></app-button>
        <app-button :loading="loading_save" v-if="baabd_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>
      <v-row v-if="baabd_header.status == 'open'">
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="baabd_header.customer_no_" placeholder="Customer No." label="Customer No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch.dialog = true" readonly></v-text-field>
                    <v-text-field dense v-model="baabd_header.customer_name" placeholder="Customer Name" label="Customer Name" outlined hide-details class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="baabd_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch2.dialog = true" readonly></v-text-field>
                    <v-text-field readonly dense v-model="baabd_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense v-model="baabd_header.contract_value" placeholder="Nilai Kontrak" label="Nilai Kontrak" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                    <app-text-field dense v-model="baabd_header.invoice_value" placeholder="Nilai Invoice" label="Nilai Invoice" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                    <app-text-field dense v-model="baabd_header.payment_value" placeholder="Nilai Payment" label="Nilai Payment" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense v-model="baabd_header.last_paid_date" placeholder="Last Paid Date" label="Last Paid Date" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense v-model="baabd_header.balance_ar_value" placeholder="Balance AR" label="Balance AR" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>
              </card-expansion>
              <card-expansion title="Rincian Surat" class="mb-6">
                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense v-model="baabd_header.warning_letter_date1" placeholder="Teguran 1" label="Teguran 1" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense v-model="baabd_header.warning_letter_date2" placeholder="Teguran 2" label="Teguran 2" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense v-model="baabd_header.warning_letter_date3" placeholder="Teguran 3" label="Teguran 3" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense v-model="baabd_header.warning_letter_date4" placeholder="Teguran 4" label="Teguran 4" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense v-model="baabd_header.legal_demand_letter1" placeholder="Somasi 1" label="Somasi 1" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense v-model="baabd_header.legal_demand_letter2" placeholder="Somasi 2" label="Somasi 2" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense v-model="baabd_header.legal_demand_letter3" placeholder="Somasi 3" label="Somasi 3" type="date" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Kronologis Penagihan" class="mb-6" :loading="loading" no-padding>
                <template slot="action">
                  <v-btn @click="showDialogAddDetail()" color="primary" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-row>
                  <v-col>
                    <v-data-table :items="baabd_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0">
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn @click="deleteDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn @click="showDialogEditDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :baabd_header="baabd_header"></document-info>
          <app-comment :document_id="parseInt(baabd_header.id)" :source_document="baabd_header.source_document" :document_no_="baabd_header.document_no_"></app-comment>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-alert type="warning" icon="mdi-information" dense> This document cannot be edited.<br /><a href="javascript:void();" @click="redirect('Finance.Baabd.Detail', { id: id })">View Detail</a> </v-alert>
        </v-col>
      </v-row>
    </app-page-detail>
    <app-dialog v-model="dialogAddDetail" title="Add Detail" width="600px">
      <v-card>
        <v-card-text>
          <input v-model="dialogAddDetail.id" />
          <v-text-field type="date" label="Tanggal" placeholder="Tanggal" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.line_date"></v-text-field>
          <v-textarea label="Deskripsi" placeholder="Deskripsi" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.line_description"></v-textarea>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="addDetail()" color="primary">Add</v-btn>
      </template>
    </app-dialog>
    <app-dialog v-model="dialogEditDetail" title="Edit Detail" width="600px">
      <v-card>
        <v-card-text>
          <input v-model="dialogEditDetail.id" />
          <v-text-field type="date" label="Tanggal" placeholder="Tanggal" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.line_date"></v-text-field>
          <v-textarea label="Deskripsi" placeholder="Deskripsi" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.line_description"></v-textarea>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="updateDetail()" color="primary">Update</v-btn>
      </template>
    </app-dialog>
    <app-table-search-dialog v-model="tsearch.dialog" :uri="tsearch.uri" :headers="tsearch.headers" @row-selected="handleRowSelectedCustomer"></app-table-search-dialog>
    <app-table-search-dialog v-model="tsearch2.dialog" :uri="tsearch2.uri" :headers="tsearch2.headers" @row-selected="handleRowSelectedCampaign"></app-table-search-dialog>
  </div>
</template>

<script>
// import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
// import ActionButton from './components/ActionButton.vue';
// import SetCloseDocument from "./components/SetCloseDocument.vue";

import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    // CommentList,
    // ApprovalList,
    DocumentInfo,
    // ActionButton,
    // SetCloseDocument,
    AppTextField,
  },
  data() {
    return {
      id: null,
      loading: false,
      notFound: false,
      data_detail: {},
      baabd_header: {},
      baabd_detail: [],
      baabd_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: {},

      customer_list: [],
      search_customer: "",
      loading_search_customer: false,
      selected_customer: {},

      datatable_detail: {
        loading: false,
        headers: [
          { text: "#", value: "action", class: "col-md-2 col-2" },
          { text: "Tanggal", value: "line_date" },
          { text: "Deskripsi", value: "line_description" },
        ],
      },
      dialogAddDetail: false,
      dialogAddDetailData: {},
      dialogEditDetail: false,
      dialogEditDetailData: {},
      dialogEditSelectedIndex: null,

      tsearch: {
        uri: "customer/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "Address", value: "address", class: "text-no-wrap" },
        ],
      },

      tsearch2: {
        uri: "campaign/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status_code", class: "text-no-wrap" },
        ],
      },
    };
  },
  watch: {
    search_campaign(n) {
      this.getCampaign(n);
    },

    selected_campaign(n) {
      console.log(n);
      if (typeof n.no_ !== "undefined") {
        this.baabd_header.campaign_no_ = n.campaign_no_;
        this.baabd_header.campaign_description = n.campaign_description;
      } else {
        this.baabd_header.campaign_no_ = "";
        this.baabd_header.campaign_description = "";
      }
    },

    search_customer(n) {
      this.getCustomer(n);
    },

    selected_customer(n) {
      console.log(n);
      console.log("typeof n: ", typeof n);

      if (typeof n !== "undefined" && n != null) {
        this.baabd_header.customer_no_ = n["no_"] ?? "";
        this.baabd_header.customer_name = n["name"] ?? "";
      } else {
        this.baabd_header.customer_no_ = "";
        this.baabd_header.customer_name = "";
      }

      this.getCampaign();
    },
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "baabd/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("baabd/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.baabd_header = res.data.data.baabd_header;
          this.baabd_detail = res.data.data.baabd_detail;
          this.baabd_approval = res.data.data.baabd_approval;

          this.selected_campaign.campaign_no_ = this.baabd_header.campaign_no_;
          this.selected_campaign.campaign_description = this.baabd_header.campaign_description;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading_save = true;
      this.showLoadingOverlay(true);
      var data = {
        baabd_header: this.baabd_header,
        baabd_detail: this.baabd_detail,
      };

      await this.$axios
        .post("baabd/save", this.objectToFormData(data))
        .then((res) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }
          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
        });
    },

    async getCustomer(search = "") {
      this.loading_search_customer = true;
      await this.$axios
        .get("baabd/get-customer", {
          params: {
            search: search,
          },
        })
        .then((res) => {
          this.customer_list = res.data.data.results;
          this.loading_search_customer = false;
        })
        .catch((error) => {
          this.loading_search_customer = false;
          this.axiosErrorHandler(error);
        });
    },

    async getCampaign(search = "") {
      this.campaign_list = [];
      this.loading_search_campaign = true;
      await this.$axios
        .get("baabd/get-campaign", {
          params: {
            search: search,
            customer_no_: this.baabd_header.customer_no_,
          },
        })
        .then((res) => {
          this.campaign_list = res.data.data.results;
          this.loading_search_campaign = false;
        })
        .catch((error) => {
          this.loading_search_campaign = false;
          this.axiosErrorHandler(error);
        });
    },

    showDialogAddDetail() {
      this.dialogAddDetailData = {};
      this.dialogAddDetail = true;
    },

    addDetail() {
      this.baabd_detail.push({
        id: this.dialogAddDetailData.id,
        line_date: this.dialogAddDetailData.line_date,
        line_description: this.dialogAddDetailData.line_description,
      });

      this.dialogAddDetailData = {};
      this.dialogAddDetail = false;
    },

    deleteDetail(item) {
      var index = this.baabd_detail.indexOf(item);
      this.baabd_detail.splice(index, 1);
    },

    showDialogEditDetail(item) {
      this.dialogEditSelectedIndex = this.baabd_detail.indexOf(item);
      this.dialogEditDetail = true;
      const cloneItem = Object.assign({}, item);
      this.dialogEditDetailData = cloneItem;
    },

    updateDetail() {
      var index = this.dialogEditSelectedIndex;
      this.baabd_detail[index].id = this.dialogEditDetailData.id;
      this.baabd_detail[index].line_date = this.dialogEditDetailData.line_date;
      this.baabd_detail[index].line_description = this.dialogEditDetailData.line_description;
      this.dialogEditDetailData = {};
      this.dialogEditDetail = false;
    },

    handleRowSelectedCustomer(item) {
      this.tsearch.dialog = false;
      this.baabd_header.customer_no_ = item.no_;
      this.baabd_header.customer_name = item.name;
    },

    handleRowSelectedCampaign(item) {
      this.tsearch2.dialog = false;
      this.baabd_header.campaign_no_ = item.no_;
      this.baabd_header.campaign_description = item.description;
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}

.custom-form-label {
  font-size: 16px;
}
.custom-form-label.dense {
  font-size: 16px !important;
  line-height: 1.375rem;
  /* buat text ada di verical tengah */
  display: flex;
  align-items: center;
}
</style>
